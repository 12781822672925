import React, { useState, useEffect } from 'react';
import {useParams} from "react-router-dom"


function GetDonationCode() {
  const hash = useParams()

  const [userHash, setUserHash] = useState("xxxxxxxxxx")
  const [copyBtnText, setCopyBtnText] = useState("Copy")
  
  useEffect( () => {
    document.title = "Donation Code | Discord Meeting - Maher Zarrai"
    setUserHash(hash.id)
  }, [])

  return (
    <div>
      <div className="container">
      <h2 style={{color: "#fff"}}>Thank you for filling the form<br />Your donation code is:</h2>
        <button className="hash">{userHash}</button>
        <button 
          className="copy"
          onClick={() => {
            navigator.clipboard.writeText(userHash)
            setCopyBtnText("Copied")
            setTimeout(() => {
              setCopyBtnText("Copy")
            }, 500)
          }}
        >
          {copyBtnText}
        </button>  
      </div>
    </div>
  )
  }

export default GetDonationCode