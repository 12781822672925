import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from "./Home"
import GetDonationCode from "./GetDonationCode"
import NotFound from "./NotFound"

function App() {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/donationCode" element={<NotFound />} />
            <Route exact path="/donationCode/:id" element={<GetDonationCode />} />
        </Routes>   
    );
}

export default App;