import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

function  NotFound() {
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
          navigate('/donationCode/notFound', { replace: true });
        }, 1);
      }, []);

    return (
        <div>Redirecting...</div>
    );
}

export default NotFound;